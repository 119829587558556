/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Segment role="contentinfo" padded id="footer">
      <div className="footerTop">

        <div className='logoWhiteDiv'>
          <img src='../logo-white.png' alt='Logo White' className='logoWhite' />
        </div>

        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <p className="item">
                <FormattedMessage
                  id="Footer-Name"
                  defaultMessage="Jodlerklub Schüpbach"
                />
              </p>
            </div>
            <div role="listitem" className="item">
              <p className="item">
                <FormattedMessage
                  id='Footer-Plz-Ort'
                  defaultMessage="3535, Schüpbach"
                />
              </p>
            </div>
          </List>
        </div>
        
        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <i className="ri-phone-line"></i>
              <a className="item" href="tel:0792487475">
                <FormattedMessage
                  id='Footer-Telefon'
                  defaultMessage="079 248 74 75"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-mail-line"></i>
              <a className="item" href="mailto:info@jodlerklub-schuepbach.ch">
                <FormattedMessage
                  id='Footer-Email'
                  defaultMessage="info@jodlerklub-schuepbach.ch"
                />
              </a>
            </div>
          </List>
        </div>
      </div>
      <hr/>
      <div className='footerBottom'>
        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <a className="item" href="./impressum">
                <FormattedMessage
                  id='Footer-Imprint'
                  defaultMessage="Impressum"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <a className="item" href="./datenschutzerklaerung">
                <FormattedMessage
                  id='Footer-privacy'
                  defaultMessage="Datenschutzerklärung"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-copyright-line"></i>
              {currentYear}&nbsp;
              <FormattedMessage
                id='Footer-Copyright'
                defaultMessage="JK Schüpbach"
              />
            </div>
          </List>
        </div>
      </div>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
