import { customBlocks } from 'components/manage/Blocks';

import { PersonView } from './components'

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';


export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['de'],
    defaultLanguage: 'de',
  };

  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      person: PersonView,
    },
  };

  config.settings.image_crop_aspect_ratios = [
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '4:3',
      ratio: 4 / 3,
    },
    {
      label: '1:1',
      ratio: 1,
    },
    {
      label: 'Header',
      ratio: 10 / 1,
    },
  ];

  config.settings.themeColors = [
    { value: 'white', title: 'No theme' },
    { value: 'primary', title: 'Primary' },
    { value: 'secondary', title: 'Secondary' },
    { value: 'tertiary', title: 'Tertiary' },
  ];

  config.blocks.blocksConfig.slider = {
    ...config.blocks.blocksConfig.slider,
    referenceContainerQuery: '.container .header',
  };

  config.settings.DSGVOBanner = {
    ...(config.settings.DSGVOBanner || {}),
    trackingId: 'G-EVH70Z0J79',
    modules: ['google', 'youtube'],
    privacy_url: '/datenschutz',
  };

  config.settings.available_colors = [
    ...config.settings.available_colors,
    '#9EC0EF',
    '#171D2A'
  ];

  /* Remove required title block since we hide it anyway */
  /* Add custom blocks and category */
  config.blocks = {
    ...config.blocks,
    blocksConfig: { ...config.blocks.blocksConfig, ...customBlocks },
    requiredBlocks: [],
    groupBlocksOrder: [
      ...(config.blocks.groupBlocksOrder),
      { id: 'special', title: 'Special' },
    ],
  };

  /* Configure block categories  */
  config.blocks.blocksConfig = {
    ...(config.blocks.blocksConfig),
    __button: {
      ...(config.blocks.blocksConfig.__button),
      mostUsed: false,
    },
    maps: {
      ...(config.blocks.blocksConfig.maps),
      mostUsed: true,
    },
    columnsBlock: {
      ...(config.blocks.blocksConfig.columnsBlock),
      mostUsed: true,
    },
    dsgvoBanner: {
      ...(config.blocks.blocksConfig.dsgvoBanner),
      group: 'special',
    }
  };

  /* Configure available block types */
  config.blocks.blocksConfig = {
    ...(config.blocks.blocksConfig),
    title: {
      ...(config.blocks.blocksConfig.title),
      restricted: true,
    },
    description: {
      ...(config.blocks.blocksConfig.description),
      restricted: true,
    },
    toc: {
      ...(config.blocks.blocksConfig.toc),
      restricted: true,
    },
    search: {
      ...(config.blocks.blocksConfig.search),
      restricted: true,
    },
    slider: {
      ...(config.blocks.blocksConfig.slider),
      restricted: true,
    },
    carousel: {
      ...(config.blocks.blocksConfig.carousel),
      restricted: true,
    },
    teaser: {
      ...(config.blocks.blocksConfig.teaser),
      restricted: true,
    },
    listing: {
      ...(config.blocks.blocksConfig.listing),
      restricted: true,
    }
  };

  /* Restrict access to Team blocks */
  /* config.blocks.blocksConfig = {
    ...(config.blocks.blocksConfig),
    isotope: {
      ...(config.blocks.blocksConfig.isotope),
      restricted: true,
    }
  } */

  /* Restrict access to News blocks */
  /* config.blocks.blocksConfig = {
    ...(config.blocks.blocksConfig),
    news: {
      ...(config.blocks.blocksConfig.news),
      restricted: true,
    }
  } */

  return config;
}
